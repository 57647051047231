import React from 'react';
import { Link } from 'react-router-dom';
import { AppIcon, SwitchButton } from '../../../index';
import NumberHelper from '../../../util/NumberHelper';
import { LPOverridable } from '../../../index';

class CardDetails extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      autoTopUp: false,
      notifications: false,
      disableInternet: false
    };
  }

  handleAutoTopUpModal() {
    this.setState({ autoTopUp: !this.state.autoTopUp });
    if (!this.state.autoTopUp === true) {
      this.setState({ showModal: true });
    }
  }
  handleDisableInternet() {
    this.setState({ disableInternet: !this.state.disableInternet });
  }
  handleNotifications() {
    this.setState({ notifications: !this.state.notifications });
  }

  render() {
    let { card } = this.props;
    return (
      <div className="row details-holder">
        <div className="top-line"></div>
        <div className="col-md-6">
          <ul>
            <li>
              Status:{' '}
              <span>
                {card.statusText}{' '}
                <AppIcon
                  name={'fa.circle'}
                  style={{
                    fontSize: 10,
                    bottom: 2,
                    position: 'relative',
                    color: card.statusText === 'OK' ? '#3FD184' : '#FE8585'
                  }}
                />
              </span>
            </li>
            <li>
              Total Balance:{' '}
              <span>{NumberHelper.currency(card.balance, card.currency)}</span>
            </li>
            <li>
              ID: <span>{card.pan}</span>
            </li>
            <li>
              Expires: <span>{card.expires}</span>
            </li>
            <li>
              <LPOverridable name={'card.page.linkTC'}>
                <Link to="/" className="link-for-terms">
                  Terms & Conditions
                </Link>
              </LPOverridable>
            </li>
            <li>
              <Link to="/fx-calculator" className="link-for-terms">
                Exchange Fee Calculator
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="control-card">
            <LPOverridable name={'card.page.autoTopUp'}>
              <li>
                Auto Top up
                <SwitchButton
                  isChecked={this.state.autoTopUp}
                  onChange={() => this.handleAutoTopUpModal()}
                />
              </li>
            </LPOverridable>
            <li>
              Disable use on the internet
              <SwitchButton
                isChecked={this.state.disableInternet}
                onChange={() => this.handleDisableInternet()}
              />
            </li>
            <li>
              Notifications
              <SwitchButton
                isChecked={this.state.notifications}
                onChange={() => this.handleNotifications()}
              />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default CardDetails;
