import React from 'react';
import { Link } from 'react-router-dom';
import { AppIcon, LPOverridable } from '../../../index';

export default class AppFooter extends React.Component<any, any> {

  render() {
    return (
      <div className='app-footer'>
        <div className='container'>
          <LPOverridable name={'footer.content'}>
          <div className='row'>
            <div className='col-md-3 footer-column'>
              <h3>LAUNCHPAD</h3>
              <Link to="/" className='footer-link'>About Launchpad</Link>
              <Link to="/" className='footer-link'>Current projects</Link>
              <Link to="/" className='footer-link'>How it works</Link>
              <Link to="/" className='footer-link'>Partners</Link>
            </div>
            <div className='col-md-3 footer-column'>
              <h3>INFORMATION</h3>
              <Link to="/" className='footer-link'>T&Cs</Link>
              <Link to="/" className='footer-link'>FAQ</Link>
              <Link to="/" className='footer-link'>Contact us</Link>
              <Link to="/" className='footer-link'>Help</Link>
              <Link to="/" className='footer-link'>Press</Link>
            </div>
            <div className='col-md-3 footer-column'>
              <h3>CONTACT</h3>
              <p className='footer-link'>Contact us by email</p>
              <a className='footer-link' href="mailto:info@launchpad.com">info@launchpad.com</a>
              <a className='footer-link' href="tel:012345678">Tel 012345678</a>
            </div>
            <div className='col-md-3 footer-column last-column'>
              <h3>CONNECT WITH US</h3>
              <div>
                <a href=""><AppIcon name={'fa.facebook-square'} style={{ fontSize: 40, color: 'white' }} /></a>
                <a href=""><AppIcon name={'fa.twitter'} style={{ fontSize: 40, color: 'white' }} /></a>
              </div>
              <div className='pannovate-logo'>
                <p>powered by</p>
                <img src={require("../../../assets/pannovate_logo.svg")} alt="pannovate-logo" />
              </div>
            </div>

            <div className='last-line'>
              <p>© Launchpad 2019 copyright</p>
            </div>

          </div>
          </LPOverridable>
        </div>
      </div>
    )
  }
}