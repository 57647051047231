import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  AppLayoutWithCard,
  FormSubmitWrapper,
  FormHandler,
  InputField
} from '../../../index';
import ForgotPasswordModel from '../models/ForgotPasswordModel';

export default class LoginPage extends Component<any, any> {
  formHandler: FormHandler;

  constructor(props: Readonly<{}>) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new ForgotPasswordModel({
        email: ' '
      }),
      {
        redirect: false
      }
    );
    this.formHandler.on('submitSuccess', (data: { success: any }) => {
      if (data.success) {
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 3000);
      }
    });
  }

  componentDidMount() {
    this.setState(state => ({
      ...state,
      record: {
        ...state.record,
        email: '',
        remember: false
      }
    }));
  }

  onSubmitSuccess(data: { success: any; data: { token: any; account: any } }) {
    if (data.success && data.data.token) {
      this.props.userLoggedIn(data.data.token, data.data.account);
    }
  }

  _getModalMessage(data: { success: any; message: any }) {
    if (!data.success) {
      return data.message;
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    let formHandler = this.formHandler;
    return (
      <AppLayoutWithCard>
        <h4>Forgot your password?</h4>
        <p>
          Don’t worry. We’ll reset your password and
          <br /> send you a link to create a new one.
        </p>
        <div className="form-wrapper">
          <FormSubmitWrapper
            formHandler={formHandler}
            buttonClass="gold-button"
            to="/login"
            buttonText="RESET PASSWORD"
            getModalMessage={(data: { success: any; message: any }) =>
              this._getModalMessage(data)
            }
          >
            <div className="login-input-wrapper">
              <InputField
                className="input-group"
                materialProps={{
                  fullWidth: true
                }}
                autoComplete={this.state.record.email}
                label="Enter E-mail"
                name="email"
                type="email"
                // placeholder={'Enter E-mail'}
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.record.email}
                handler={formHandler}
              />
            </div>
          </FormSubmitWrapper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 20
            }}
          >
            <Link
              to="/login"
              style={{
                color: '#6C6C6C',
                textDecoration: 'none',
                fontWeight: 'lighter',
                fontSize: 15
              }}
            >
              Back to login
            </Link>
          </div>
        </div>
      </AppLayoutWithCard>
    );
  }
}
