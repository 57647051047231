import overrides from './overrides';
import styles from './styles';
// import routes from './routes';
import reducers from './reducers';

const configuration = {
    overrides,
    styles,
    // routes,
    reducers
}

export default configuration;
