import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { OverrideService } from '../../../logic/services';

const { palletePrimary } = OverrideService.getThisOrOverridenColors();

const theme = createMuiTheme({
  // For Underline Color After Click
  palette: {
    primary: { main: palletePrimary }
  },
  // For Underline Hover Color
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#fff',
        borderWidth: 1,
        borderRadius: 4
      },
      root: {
        color: 'rgba(0, 0, 0, 1)',
        '&$focused': {
          borderColor: '#fff'
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#fff'
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(0, 0, 0, 0.5)'
      }
    }
  }
});

export default class InputField extends Component<any, any> {
  /**
   * Get class name
   *
   * @returns {string}
   */
  getClassName() {
    const classNames = ['input-group'];
    if (this.props.fullWidth) {
      classNames.push('fulled');
    }

    return classNames.join(' ');
  }

  getValidationError() {
    const messages: any[] = [];

    if (this.props.handler) {
      this.props.handler
        .getErrors()
        .filter((x: { field: any }) => x.field === this.props.name)
        .forEach((error: { message: any }) => messages.push(error.message));
    }
    if (messages.length === 0) {
      return null;
    }
    return (
      <div className="validation-message" key={`${this.props.name}-error`}>
        {messages.join('<br/>')}
      </div>
    );
  }

  /**
   * Handle input change
   *
   * @param e
   */
  handleInputChange(e: any) {
    if (this.props.handler) {
      this.props.handler.handleInputChange(e);
    }
    if (this.props.onInputText) {
      this.props.onInputText(e.target.value, this.props.name);
    }
  }

  /**
   * Render input
   *
   * @returns {XML}
   */
  render() {
    // let className = this.getClassName();
    const validationError = this.getValidationError();

    return (
      <div
        className={this.props.className}
        style={{ position: 'relative' }}
        id={validationError ? 'outlined-error' : ''}
      >
        <MuiThemeProvider
          theme={this.props.muiTheme ? this.props.muiTheme : theme}
        >
          <TextField
            error={!!validationError}
            variant="outlined"
            autoComplete={this.props.autoComplete}
            label={this.props.label}
            className={this.props.className}
            type={this.props.type}
            placeholder={this.props.placeholder}
            name={this.props.name}
            inputProps={this.props.inputProps}
            // margin="normal"
            // InputLabelProps={{
            //   shrink: true,
            // }}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            value={this.props.value}
            onChange={this.handleInputChange.bind(this)}
            {...this.props.materialProps}
          />
        </MuiThemeProvider>
        {validationError}
      </div>
    );
  }
}
