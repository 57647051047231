import React from 'react';
import CardModel from '../models/CardModel';
import CircularProgress from '@material-ui/core/CircularProgress';

class ShowPin extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            pin: '',
            arrayPin: '',
            text: '',
            loading: false
        }
    }

    async loadPin() {
        this.setState({
            loading: true
        })
        let { card } = this.props;

        let pin = await (new CardModel()).fetchPin(card);

        this.setState({
            pin: pin,
            arrayPin: Array.from(pin) as any,
            text: 'Hide',
            loading: false
        });
    }

    componentDidMount() {
        this.loadPin();
    }

    hidePin() {
        this.setState({
            pin: '',
            arrayPin: Array.from('****') as any,
            text: 'Unhide'
        });
    }
    unhidePin() {
        this.loadPin();
    }

    render() {
        let { pin, arrayPin, text } = this.state;

        let innerElement = (
            <div className='col-md-8'>
                <h3>Your Card PIN</h3>

                <div className='col-md-12 pin-wrapper'>
                    <div className='pin-number-holder'>
                        <span>{arrayPin[0]}</span>
                    </div>
                    <div className='pin-number-holder'>
                        <span>{arrayPin[1]}</span>
                    </div>
                    <div className='pin-number-holder'>
                        <span>{arrayPin[2]}</span>
                    </div>
                    <div className='pin-number-holder'>
                        <span>{arrayPin[3]}</span>
                    </div>
                    {pin !== '' && pin ?
                        <h4 onClick={() => this.hidePin()}>{text}</h4>
                        :
                        <h4 onClick={() => this.unhidePin()}>{text}</h4>
                    }
                </div>

                <p>You can change your PIN at any ATM</p>
            </div>
        );

        if (this.state.loading) {
            innerElement = <div style={{ textAlign: 'center' }}><CircularProgress className='circle-progress' /></div>;
        }

        return (
            <div className="row details-holder justify-content-center align-items-center" style={{ flexDirection: 'column' }}>
                <div className='top-line'></div>
                {innerElement}
            </div>
        )
    }
}

export default ShowPin;
