import React from 'react';
import { connect } from 'react-redux';
import LoadingHelper from '../../util/LoadingHelper';
import LoadingModal from './components/LoadingModal';
import InfoModal from './components/InfoModal';
import StatementModals from '../../modules/statements/StatementModals';
import LoadCardModal from '../../modules/dashboard/modals/LoadCardModal';
import CardModals from '../../modules/card/CardModals';

const APP_MODALS = {
  ...CardModals,
  ...LoadCardModal,
  ...StatementModals,
  'INFO_MODAL': InfoModal

  // LOADING_MODAL: LoadingModal
}

class Modals extends React.Component {

  render() {
    let {
      modals
    } = this.props;

    let children = [];

    modals.forEach((modal, index) => {
      if (APP_MODALS[modal.id]) {
        let SpecificModal = APP_MODALS[modal.id];
        let props = modal.props ? modal.props : {}
        children.push(<SpecificModal key={'modal' + index} {...props} />)
      }
    });

    return (
      <div>
        {children}
        <LoadingModal inBody={true} ref={element => LoadingHelper.setLoadingModal(element)} />
      </div>
    );

  }

}

const mapStateToProps = state => {
  return {
    modals: state.modals
  }
}
export default connect(mapStateToProps, null)(Modals);