import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LPOverridable from '../../components/overrides/LPOverridable';
import ProfileImage from '../../components/images/ProfileImage';
import { fullDate, date } from '../../util/DateHelper';

const Profile = ({ profile }) => {
  const profileImg = <ProfileImage profile={profile} />;
  return (
    <div className="appContainer">
      <div className="container">
        <div className="profile-container account-holder">
          <div className="owner-holder">
            <div className="profile-image-holder">{profileImg}</div>
            <div className="owner-name">
              <p>ACCOUNT OWNER</p>
              <h3>{profile.fullName}</h3>
              <p>LAST LOGIN: {fullDate(profile.lastLogin)}</p>
            </div>
          </div>

          <div className="container" style={{ paddingTop: 50 }}>
            <div className="row">
              <div className="col-md-6">
                <div className="info-wrapper">
                  <div className="profile-header">
                    <h3>Basic info</h3>
                  </div>
                  <ul>
                    <li>
                      Title: <span>{profile.title || '-'}</span>
                    </li>
                    <li>
                      First name: <span>{profile.firstName || '-'}</span>
                    </li>
                    <li>
                      Middle name: <span>{profile.middleName || '-'}</span>
                    </li>
                    <li>
                      Last name: <span>{profile.lastName || '-'}</span>
                    </li>
                    <li>
                      Date of birth:{' '}
                      <span>{date(profile.birthDate) || '-'}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-wrapper">
                  <div className="profile-header">
                    <h3>Address</h3>
                  </div>
                  <ul>
                    <li>
                      Street: <span>{profile.addressOne || '-'}</span>
                    </li>
                    <li>
                      Sub Street: <span>{profile.addressTwo || '-'}</span>
                    </li>
                    <li>
                      Postal code: <span>{profile.postCode || '-'}</span>
                    </li>
                    <li>
                      City: <span>{profile.city || '-'}</span>
                    </li>
                    <li>
                      Country: <span>{profile.country.name || '-'}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="info-wrapper">
                  <div className="profile-header">
                    <h3>Contact information</h3>
                  </div>
                  <ul>
                    <li>
                      Email: <span>{profile.email || '-'}</span>
                    </li>
                    <li>
                      Mobile No.: <span>{profile.mobile || '-'}</span>
                    </li>
                    <li>
                      Landline: <span>{profile.landline || 'none'}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-wrapper">
                  <div className="profile-header">
                    <h3>Unique Reference No</h3>
                  </div>
                  <ul>
                    <li>
                      Reference number:{' '}
                      <span>{profile.customerReference || '-'}</span>
                    </li>
                    <li className="red-info">
                      provide this reference to your contacts who want to send
                      you money.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingTop: 25 }}>
          <div className="col-md-6 terms-holder">
            <div className="profile-container">
              <h5 className="privacy-text">
                Read our Privacy Policy{' '}
                <LPOverridable name="profile.page.privacyLink">
                  <Link to="/" className="terms-link">
                    here
                  </Link>
                </LPOverridable>
              </h5>
            </div>
          </div>
          <div className="col-md-6 terms-holder">
            <div className="profile-container">
              <h5 className="privacy-text">
                Read our Terms & Conditions{' '}
                <LPOverridable name="profile.page.termsLink">
                  <Link to="/" className="terms-link">
                    here
                  </Link>
                </LPOverridable>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { auth: { profile: any } }) => {
  return {
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(Profile);
