import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Api from '../../logic/api/Api';
import FXRatesItem from './FXRatesItem';
import AppButton from '../../components/crud/AppButton';
import { getCurrencySymbol } from '../../util/CurrencyHelper';
import Auth from '../../logic/services/AuthService';

const ExchangeConfirmationDialog = ({
  amount,
  baseCurrency,
  convertedCurrency,
  onHide
}) => {
  const [loading, setLoading] = useState(true);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);

  async function fetchConvertedAmount() {
    const result = await Api.call('rates/calculate', {
      from: baseCurrency.currency,
      to: convertedCurrency.currency,
      amount
    });
    const returndAmount = result ? result.data : 0;
    setLoading(false);
    return setConvertedAmount(returndAmount);
  }

  useEffect(() => {
    // Call api to get converted value
    fetchConvertedAmount();
  }, []);

  async function handleSubmitButton() {
    setLoading(true);
    const result = await Api.call('balance/transfer', {
      balanceFrom: baseCurrency.id,
      balanceTo: convertedCurrency.id,
      amount
    }).catch(e => {
      setLoading(false);
      setConfirmationSuccess(false);
      return setConfirmationMessage(e.message);
    });
    setLoading(false);
    if (result && result.success) {
      setConfirmationSuccess(true);
      Auth.refreshAuth();
      return setConfirmationMessage('You have successfully exchanged money.');
    }
    return null;
  }

  if (confirmationMessage) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column-reverse',
            alignItems: 'center',
            paddingBottom: 10
          }}
        >
          {/* Show icon based on result.success */}
          {confirmationSuccess ? (
            <img
              src={require('../../assets/check-circle-1.svg')}
              alt="Success"
              style={{ width: 50, height: 50 }}
            />
          ) : (
            <img
              src={require('../../assets/alert-circle.svg')}
              alt="Success"
              style={{ width: 50, height: 50 }}
            />
          )}
        </div>
        <div
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <p style={{ fontSize: 20, textAlign: 'center' }}>
            {confirmationMessage}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            flexDirection: 'column-reverse',
            width: '100%'
          }}
        >
          <AppButton type="submit" onClick={() => onHide()}>
            OK
          </AppButton>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress className="circle-progress" size={20} />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div style={{ justifyContent: 'space-evenly', flex: 1 }}>
          <p
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              paddingBottom: 20
            }}
          >
            Are you sure you want to exchange
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <FXRatesItem
              {...baseCurrency}
              text={
                getCurrencySymbol(baseCurrency.currency) +
                parseFloat(amount).toFixed(2)
              }
              textStyle={{ fontWeight: 'bold' }}
            />
            <p
              style={{
                fontSize: 20,
                paddingHorizontal: 20
              }}
            >
              TO
            </p>
            <FXRatesItem
              {...convertedCurrency}
              text={
                getCurrencySymbol(convertedCurrency.currency) +
                parseFloat(convertedAmount).toFixed(2)
              }
              textStyle={{ fontWeight: 'bold' }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <div style={{ flex: 1, marginRight: 10 }}>
          <AppButton type="submit" onClick={() => onHide()}>
            NO
          </AppButton>
        </div>
        <div style={{ flex: 1 }}>
          <AppButton type="submit" onClick={() => handleSubmitButton()}>
            YES
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default ExchangeConfirmationDialog;
