import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default class SideMenuItem extends Component {

  /**
   * Set initial state in constructor
   */
  constructor() {
    super();
    this.state = {
      opened: false
    }
  }

  /**
   * Called on link click
   */
  linkClick() {
    this.setState({
      opened: !this.state.opened
    })
  }

  /**
   * Render function
   *
   * @returns {XML}
   */
  render() {

    // Build icon
    let icon = 'fa fa-' + this.props.icon;

    // Build children
    let children =[];
    if(this.props.children) {
      this.props.children.map((child, index) => {
        let key = 'side-menu-item-' + index;
        children.push(<SideMenuItem {...child} key={key}/>);
      });
    }

    // Get children components
    let childPart = '';
    let path = this.props.path;
    if(children.length) {
      childPart = (
        <ul className="sub-nav">
          {children}
        </ul>
      );
    }

    // Check if item is active
    let isActive = this.isPathActive(this.props);

    // Check if item should be opened
    let opened = true;
    // if(isActive) {
    //   opened = true;
    // }

    let innerComponent = null;

    if(path) {
      innerComponent = (
        <Link to={path} onClick={this.linkClick.bind(this)}>
          <i data-toggle="tooltip" data-placement="right" title={this.props.label} className={icon} aria-hidden="true"></i>
          {/* <span>{this.props.label}</span> */}
        </Link>
      );
    } else {
      innerComponent = (
        <a href="javascript:void(0)" onClick={this.linkClick.bind(this)}>
          <i data-toggle="tooltip" data-placement="right" title={this.props.label} className={icon} aria-hidden="true"></i>
          {/* <span>{this.props.label}</span> */}
        </a>
      );
    }

    // Render item
    return(
      <li className={isActive ? 'active' : null}>
        {isActive ? <div className='active-blue-line'></div> : null}
        {innerComponent}
        {opened ? childPart : null}
      </li>
    )
  }

  /**
   * Check if path is active
   *
   * @param item
   * @returns {boolean}
   */
  isPathActive(item) {

    // Get current path from context
    // let currentPath = this.context.router.route.location.pathname;
    let currentPath = window.location.pathname;

    // If paths same?
    if(currentPath === item.path) {
      return true;
    }

    // Check if some child is active
    if(item.children) {
      for(let i = 0; i < item.children.length; i++) {
        let childActive = this.isPathActive(item.children[i]);

        if(childActive) {
          return true;
        }
      }
    }

    // Default false
    return false;
  }
}

SideMenuItem.contextTypes = {
  router: PropTypes.object,
};
