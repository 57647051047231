import AppStore from '../logic/store/AppStore';

export function getCurrency() {
  const state = AppStore.getState();
  return state.auth.profile.currency;
}

export function getCurrencySymbol(currency = null) {
  if (!currency) {
    currency = getCurrency();
  }

  let map = {
    EUR: '€',
    USD: '$',
    GBP: '£',
    SEK: 'kr'
  };

  return map[currency] || currency;
}

export function beforeOrAfter(currency = null) {
  if (!currency) {
    currency = getCurrency();
  }

  const mappings = {
    GBP: -1,
    USD: -1,
    EUR: -1,
    SEK: 1
  };

  if (mappings[currency]) {
    return mappings[currency];
  }

  return 1;
}

export default {
  getCurrency,
  getCurrencySymbol,
  beforeOrAfter
};
