import React from 'react';
import ModalDialog from '../../../components/modal/ModalDialog';
import $ from 'jquery';
import CardService from '../CardService';
import { connect } from 'react-redux';
import ModalService from '../../../logic/services/ModalService';
import LoadingHelper from '../../../util/LoadingHelper';
import CardModel from '../models/CardModel';
import AuthService from '../../../logic/services/AuthService';
import { Link } from 'react-router-dom';
import { AppIcon, AppButton, LPOverridable } from '../../../index';

export const NAME = 'ACTIVATE_CARD';

class ActivateCardModal extends React.Component<any, any> {

  state = {
    show: true,
    digits: ['', '', '', '']
  }

  onClose() {
    ModalService.hideModal('ACTIVATE_CARD');
  }


  nextFocus(e: React.ChangeEvent<HTMLInputElement>, index: number) {

    let digits = [...this.state.digits];

    digits[index] = e.target.value;

    let regex = /^[0-9\b]+$/;

    let num = regex.test(digits[index]);

    if (e.target.value === '' || num) {
      this.setState({
        digits
      });
    } else {
      return;
    }

    if (e.target.value.length == 1) {
      $(e.target).next().focus();
    } else {
      $(e.target).prev().focus();
    }
  }

  _activateCard() {
    let digits = this.state.digits.filter(x => x != '');

    if (digits.length < 4) return;

    let cardNumber = digits.slice(0, 4).join('');

    let card = CardService.getDefaultCard(this.props.paymentDevices);
    // Order card here
    LoadingHelper.executeAction(() => {
      return this._actualActivateCard(card, cardNumber);
    }, {
      successMessage: 'Your card is activated!',
      loadingMessage: 'Activating your card...',
      failureMessage: 'Card activation failed!',
      beforeClose: (status: string) => {
        if (status != 'failure') {
          AuthService.refreshAuth();
          this.onClose();
        }
      }
    })
  }

  async _actualActivateCard(card: any, cardNumber: string) {
    let cardModel = (new CardModel());
    let result = await cardModel.activatePaymentDevice(card, cardNumber);

    if (result) {
      this.onClose();
      return true;
    }

    throw new Error('Card activation failed');
  }

  render() {

    let user = this.props.profile;

    if (!user) return null;

    return (
      <ModalDialog
        show={this.state.show}
        onClose={this.onClose.bind(this)}
      >
        <div className="transaction-modal">

          <div className='close-btn'>
            <AppIcon name={'fa.times'} style={{ fontSize: 24, color: 'rgba(0,0,0,0.4)', cursor: 'pointer' }} onClick={() => ModalService.hideModal()} />
          </div>
          <div className="inner-container">
            <h1>Card Activation</h1>
            <p>Please enter last four digits of your card pan number</p>
            <LPOverridable name={'acivate.card.image'}>
              <img src={require('../../../assets/activate_card.png')} alt="activate-card" />
            </LPOverridable>
            <div className="container" style={{ maxWidth: 1000 }}>
              <div className="row" style={{ justifyContent: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                  <div className='input-wrapper' style={{ flexDirection: 'row', marginTop: 30, marginBottom: 30 }}>
                    <input value={this.state.digits[0]} type="text" maxLength={1} className='input-pin inputs' onChange={(e) => this.nextFocus(e, 0)} autoFocus={true} />
                    <input value={this.state.digits[1]} type="text" maxLength={1} className='input-pin inputs' onChange={(e) => this.nextFocus(e, 1)} />
                    <input value={this.state.digits[2]} type="text" maxLength={1} className='input-pin inputs' onChange={(e) => this.nextFocus(e, 2)} />
                    <input value={this.state.digits[3]} type="text" maxLength={1} className='input-pin inputs' onChange={(e) => this.nextFocus(e, 3)} />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <AppButton onClick={() => this._activateCard()}>ACTIVATE CARD</AppButton>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </ModalDialog>
    );
  }
}
const mapStateToProps = (state: { paymentDevices: any; auth: { profile: any; }; }) => {
  return {
    paymentDevices: state.paymentDevices,
    profile: state.auth.profile
  }
}

export default connect(mapStateToProps)(ActivateCardModal);