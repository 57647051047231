import React from 'react';
import SideMenuItem from './SideMenuItem';
import { LPOverridable } from '../../../index';

export default class AppSideBar extends React.Component<any, any>  {

  constructor(props: any) {
    super(props);

    this.state = {
      navigation: this.getNavigation(),
      mobileSideState: props.isOpen
    }
  }

  componentDidMount() {
    this.setState({ mobileSideState: this.props.isOpen });
  }

  static getDerivedStateFromProps(props: { isOpen: any; }, state: any) {
    if (props.isOpen !== state.mobileSideState) {
      return {
        mobileSideState: props.isOpen,
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  render() {
    let items = [] as any;
    this.state.navigation.forEach((item: any, index: any) => {
      let key = 'side-menu-item-' + index;
      items.push(<SideMenuItem {...item} key={key} />)
    });

    let {mobileSideState} = this.state;
    let mobileClass = '';

    if(mobileSideState){
      mobileClass = 'mobile-side-bar'
    }

    return (
      <div className={"side-bar " + mobileClass}>
        {mobileSideState ? <div className='phone-overlay'></div> : null}
        <LPOverridable name={'sidemenu.logo'}>
          <div className="dash-logo"><img src={require('../../../assets/Launchpad_logo.png')} alt="logo" /></div>
        </LPOverridable>
        <ul className="sideMenuList">
          {items}
        </ul>
      </div>
    )
  }

  getNavigation() {
    return [
      {
        label: 'Dashboard',
        icon: 'tachometer',
        path: '/'
      },
      {
        label: 'Card',
        icon: 'credit-card',
        path: '/card'
      },
      {
        label: 'Statements',
        icon: 'line-chart',
        path: '/statements'
      },
      {
        label: 'Profile',
        icon: 'user-circle-o',
        path: '/profile'
      },
    ];
  }
}
