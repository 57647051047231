import React from 'react';
import AppCard from '../../components/elements/AppCard';
import DashboardHeader from './components/DasboardHeader';
import LatestTransactions from './components/LatestTransactions';
import LatestNotifications from './components/LatestNotifications';

const Dashboard: React.FC = () => {
  return (
    <div className="appContainer">
      <DashboardHeader />
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <AppCard name="exchange" title="Latest Transactions">
              <LatestTransactions />
            </AppCard>
          </div>
          <div className="col-xl-6">
            <AppCard name="comment-o" title="New Notifications">
              <LatestNotifications />
            </AppCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
