import React from 'react';
import { connect } from 'react-redux';
import ProfileImage from '../../../components/images/ProfileImage';
import DateHelper from '../../../util/DateHelper';
import { Link } from 'react-router-dom';
import CardService from '../CardService';
import { AppIcon, AppButton, LPOverridable } from '../../../index';
import CardDetails from '../components/CardDetails';
import LockCard from '../components/LockCard';
import ShowPin from '../components/ShowPin';
import ModalService from '../../../logic/services/ModalService';

class CardPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      type: ''
    };
  }
  changeContent() {
    let paymentDevice = CardService.getDefaultCard(this.props.paymentDevices);

    switch (this.state.type) {
      case 'edit':
        return <CardDetails card={paymentDevice}></CardDetails>;
      case 'lock':
        return <LockCard card={paymentDevice}></LockCard>;
      case 'pin':
        return <ShowPin card={paymentDevice}></ShowPin>;
      default:
        return;
    }
  }

  render() {
    let paymentDevice = CardService.getDefaultCard(this.props.paymentDevices);
    if (!paymentDevice) return null;

    let profile = this.props.profile;

    let cardActive = paymentDevice.isLive;
    // let cardActive = false;

    let loadAccount = (
      <div
        onClick={() =>
          ModalService.showModal('LOAD_CARD_MODAL', { profile: profile })
        }
        className="send-load-btn-wrapper"
      >
        {' '}
        <div className="send-load-icon-wrapper">
          {' '}
          <AppIcon
            name={'fa.plus'}
            style={{ fontSize: 24, color: cardActive ? 'white' : 'black' }}
          />{' '}
        </div>
        Load
      </div>
    );
    let sendMoney = (
      <Link to="/send-money" className="send-load-btn-wrapper">
        {' '}
        <div className="send-load-icon-wrapper">
          <AppIcon
            name={'fa.refresh'}
            style={{ fontSize: 24, color: cardActive ? 'white' : 'black' }}
          />{' '}
        </div>
        Send money
      </Link>
    );

    let { type } = this.state;

    let activeCardBtn = null as any;
    let activeCardOverlay = null as any;

    if (!cardActive) {
      activeCardBtn = (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1
          }}
        >
          <AppButton
            onClick={() =>
              ModalService.showModal('ACTIVATE_CARD', { paymentDevice })
            }
          >
            ACTIVATE CARD
          </AppButton>
        </div>
      );
      activeCardOverlay = (
        <div className="card-not-active-overlay">
          <h3>ACTIVATE CARD</h3>
        </div>
      );
    }

    if (paymentDevice.locked) {
      activeCardOverlay = (
        <div className="card-not-active-overlay">
          <AppIcon name={'fa.lock'} style={{ fontSize: 100, color: 'white' }} />
        </div>
      );
    }

    return (
      <div className="appContainer">
        <div className="container">
          <div className="card-wrapper">
            <div className="card-navigation-wrapper">
              <div style={{ position: 'relative' }}>
                {activeCardOverlay}
                <LPOverridable name={'card.image'}>
                  <img
                    src={require('../../../assets/card.png')}
                    className="card-page-card-image"
                  />
                </LPOverridable>
              </div>
              <div className="card-page-card-navigation">
                <ul className={!cardActive ? 'not-active' : ''}>
                  <li
                    className={type === 'edit' ? 'active' : ' '}
                    onClick={() => this.setState({ type: 'edit' })}
                  >
                    <AppIcon name={'fa.pencil'} style={{ fontSize: 24 }} />
                  </li>
                  <li
                    className={type === 'lock' ? 'active' : ' '}
                    onClick={() => this.setState({ type: 'lock' })}
                  >
                    <AppIcon name={'fa.key'} style={{ fontSize: 24 }} />
                  </li>
                  <li
                    className={type === 'pin' ? 'active' : ' '}
                    onClick={() => this.setState({ type: 'pin' })}
                  >
                    <AppIcon name={'fa.ellipsis-h'} style={{ fontSize: 24 }} />
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="send-load-btn-holder"
              style={{
                opacity: cardActive ? 1 : 0.2,
                pointerEvents: cardActive ? 'auto' : 'none'
              }}
            >
              <LPOverridable name={'card.action.buttons'} card={paymentDevice}>
                {loadAccount}
                {sendMoney}
              </LPOverridable>
            </div>
            <div style={{ maxWidth: 600, width: '100%', marginTop: 50 }}>
              {this.changeContent()}
              {activeCardBtn}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: {
  paymentDevices: any;
  auth: { profile: any };
}) => {
  return {
    paymentDevices: state.paymentDevices,
    profile: state.auth.profile
  };
};
export default connect(mapStateToProps)(CardPage);
