import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { OverrideService } from '../../logic/services';
import _ from 'lodash';

interface IProps {
  isLoading?: boolean;
  customstyle?: any;
  onClick?: () => void;
}

export class AppButton extends React.Component<IProps> {
  _getButtonContent() {
    if (this.props.isLoading) {
      return <CircularProgress className="circle-progress" size={20} />;
    }

    return this.props.children;
  }

  render() {
    return (
      <div className="gradient-button" style={this.props.customstyle}>
        <Button variant="contained" className="custom-btn" {...this.props}>
          {this._getButtonContent()}
        </Button>
      </div>
    );
  }
}

// export default AppButton;
export default OverrideService.thisOrOverridenComponent(AppButton, 'ui.button');
