import React from 'react';
import { Link } from 'react-router-dom';
import { AppList } from '../../../index';
import NotificationModel from '../../notifications/models/NotificationModel';
import NotificationListItem from '../../notifications/components/NotificationListItem';

export default class LatestNotifications extends React.Component<any, any> {
  _renderTransactionItem(item: any) {
    return <NotificationListItem notification={item} key={item.id} />;
  }

  render() {
    return (
      <div style={{ position: 'relative', minHeight: 445 }}>
        <ul
          style={{ padding: 0, margin: 0, maxHeight: 390, overflow: 'hidden' }}
        >
          <AppList
            model={NotificationModel}
            params={{
              limit: 5
            }}
            renderWrapper={(items: React.ReactNode) => (
              <ul style={{ padding: 0 }}>{items}</ul>
            )}
            renderItem={(item: any) => this._renderTransactionItem(item)}
            renderNoItems={() => (
              <div className="col-sm-12 well text-center">
                <div style={{ margin: '20px', color: 'black' }}>
                  <em>No new notifications</em>
                </div>
              </div>
            )}
          />
        </ul>

        <Link to="/notifications" className="go-to-inbox">
          All notifications
        </Link>
      </div>
    );
  }
}
