import React from 'react';
import { connect } from 'react-redux';
import ProfileImage from '../../../components/images/ProfileImage';
import { AppIcon } from '../../../index';
import DateHelper from '../../../util/DateHelper';
import { logoutTriggerAction, loginTriggerAction } from '../../../modules/auth/AuthActions';
import { Link } from 'react-router-dom';

class AppTopBar extends React.Component<any, any> {



    logoutUser() {
        this.props.userLoggedOut(this.props.token);
    }

    render() {
        let profile = this.props.profile;

        let profileImg = (<ProfileImage profile={profile} />);

        return (
            <div className='top-bar'>
                <div className='top-bar-info'>
                    <div className='profile-image-holder'>
                        {profileImg}
                    </div>
                    <div className='name-holder'>
                        <h4>{profile.fullName}</h4>
                        <p>Last Login: {DateHelper.fullDate(profile.lastLogin)}</p>
                    </div>
                    <div className='white-line'></div>
                    <Link to='/notifications'>
                        <AppIcon name={'fa.bell'} style={{ fontSize: 21, color: 'white', cursor: 'pointer' }} />
                    </Link>
                    <a style={{ textDecoration: 'none', paddingLeft: 20 }} onClick={() => this.logoutUser()}>
                        <i data-toggle="tooltip" data-placement="right" title={'Logout'} className={'fa fa-sign-out'} aria-hidden="true" style={{ fontSize: 24, color: 'white', cursor: 'pointer' }}></i>
                    </a>

                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: { auth: { token: any; profile: any; }; }) => {
    return {
        token: state.auth.token,
        profile: state.auth.profile,
    }
}

const mapDispatchToProps = (dispatch: { (arg0: { type: string; payload: {}; }): void; (arg0: { type: string; payload: { token: any; account: any; }; }): void; }) => {
    return {
        userLoggedOut: () => {
            return dispatch(logoutTriggerAction())
        },
        userLoggedIn: (token: any, account: any) => {
            return dispatch(loginTriggerAction(token, account))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AppTopBar);