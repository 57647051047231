import React from 'react';
import {
  FormSubmitWrapper,
  InputField,
  FormHandler,
  AppButton
} from '../../../index';
import AppDatePicker from '../../../components/elements/form/date/AppDatePicker';
import FxCalculatorModel from '../models/FxCalculatorModel';
import AppSelect from '../../../components/elements/form/AppSelect';
import CurrencyModel from '../models/CurrencyModel';
import AppRadioInput from '../../../components/elements/form/AppRadioInput';
import NumberHelper from '../../../util/NumberHelper';
import moment from 'moment';

const SectionTitle = props => {
  return (
    <div
      style={{
        width: '100%',
        padding: 10,
        marginBottom: 20,
        backgroundColor: 'rgba(173, 138, 78, 0.7)'
      }}
    >
      <h5 style={{ color: 'white' }}>{props.children}</h5>
    </div>
  );
};

export default class CalculatorComponent extends React.Component<any, any> {
  formHandler: FormHandler;

  initialState: any;

  constructor(props: any) {
    super(props);

    this.initialState = {
      transactionDate: moment().format('YYYY-MM-DD'),
      transactionType: 'nonEuroPrepaidCardPurchases',
      transactionCurrency: null,
      amount: 0,
      sourceCurrency: 'GBP'
    };

    this.formHandler = new FormHandler(
      this,
      new FxCalculatorModel(this.initialState),
      {
        submitError: ''
      }
    );

    this.formHandler.on('submitSuccess', data => {
      this.setState({
        exchangeInfo: data.data
      });
    });

    this.formHandler.on('submitError', data => {
      this.setState(
        {
          submitError: data.message
        },
        () => {
          setTimeout(() => {
            this.setState({
              submitError: ''
            });
          }, 3000);
        }
      );
    });
  }

  _resetForm() {
    this.formHandler.setState({
      record: this.initialState
    });
  }

  _getForm() {
    const { formHandler } = this;

    return (
      <FormSubmitWrapper
        formHandler={formHandler}
        to="/"
        buttonText="Calculate"
        hideSubmit
      >
        <div className="login-input-wrapper">
          <AppDatePicker
            className="create-account-input-group"
            id="js-date-picker"
            value={this.state.record.transactionDate}
            name="transactionDate"
            label="Transaction date"
            style={{ width: '100%' }}
            handler={formHandler}
            datePickerProps={{
              maxDate: new Date()
            }}
            // children={(
            //   <div className="time-picker-container"></div>
            // )}
          />
        </div>
        <div className="login-input-wrapper">
          <AppSelect
            className="create-account-input-group"
            materialProps={{
              fullWidth: true
            }}
            label="Currency"
            textFieldProps={{
              label: 'Currency',
              InputLabelProps: {
                shrink: true
              }
            }}
            model={CurrencyModel}
            name="transactionCurrency"
            value={this.state.record.transactionCurrency}
            handler={formHandler}
            extractName={option => option.code + ' (' + option.name + ')'}
            extractValue={option => option.code}
          />
        </div>
        <div className="login-input-wrapper">
          <InputField
            className="input-group"
            materialProps={{
              fullWidth: true
            }}
            label="Transaction Amount"
            name="amount"
            type="number"
            value={this.state.record.amount}
            handler={formHandler}
          />
        </div>
        <div className="login-input-wrapper">
          <AppRadioInput
            name="transactionType"
            labelText="Non-Euro Prepaid Card Purchases"
            handler={formHandler}
            value="nonEuroPrepaidCardPurchases"
            checked={
              this.state.record.transactionType ===
              'nonEuroPrepaidCardPurchases'
            }
          />
          <AppRadioInput
            name="transactionType"
            labelText="Non-Euro ATM Withdrawals"
            handler={formHandler}
            value="nonEuroAtmWithdrawals"
            checked={
              this.state.record.transactionType === 'nonEuroAtmWithdrawals'
            }
          />
        </div>
        <div className="login-input-wrapper">
          <InputField
            className="input-group"
            materialProps={{
              fullWidth: true,
              disabled: true
            }}
            autoComplete={this.state.record.password}
            label="Card currency"
            value={'GBP'}
            editable={false}
            handler={formHandler}
          />
        </div>
        <div className="row">
          <div className="d-flex justify-content-center col-sm-6 mb-2">
            <AppButton type="submit" isLoading={this.state.isLoading}>
              Calculate
            </AppButton>
          </div>
          <div className="d-flex justify-content-center col-sm-6 mb-2">
            <AppButton onClick={() => this._resetForm()}>Reset</AppButton>
          </div>
        </div>
      </FormSubmitWrapper>
    );
  }

  _getResult() {
    const Wrapper = props => (
      <div className="col-sm-6 text-left">
        <SectionTitle>Conversion summary</SectionTitle>
        {props.children}
      </div>
    );

    const transactionFees: any = this.state.exchangeInfo || null;

    if (!transactionFees) {
      return (
        <Wrapper>
          <div className="text-center">
            <em>please submit the form</em>
          </div>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            minHeight: 550,
            width: '100%'
          }}
        >
          <div
            style={{
              width: '100%',
              padding: 20,
              paddingTop: 0
            }}
          >
            <h6 className="text-center" style={{ fontWeight: 'bold' }}>
              Your Card Currency Amount
            </h6>
            <h2
              className="text-center"
              style={{ fontWeight: 'bold', padding: '15px 0' }}
            >
              {NumberHelper.currency(
                transactionFees.destinationAmount,
                transactionFees.destinationCurrency
              )}
            </h2>
            <p>Effective Transaction Conversion Rate Including All Fees*:</p>
            <p style={{ fontWeight: 'bold' }}>
              1 {transactionFees.sourceCurrency} ={' '}
              {transactionFees.effectiveRate}{' '}
              {transactionFees.destinationCurrency}
            </p>
          </div>
          <SectionTitle>Transaction Fees</SectionTitle>
          <div
            style={{
              textAlign: 'left',
              width: '100%',
              fontSize: 14
            }}
          >
            <span>
              Non-Euro Prepaid Card Purchases are subject to a{' '}
              {transactionFees.bankFeePct}% fee on the Mastercard conversion
              value for this transaction:
            </span>
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              {transactionFees.schemeAmount}{' '}
              {transactionFees.destinationCurrency} (1{' '}
              {transactionFees.sourceCurrency} = {transactionFees.schemeRate}{' '}
              {transactionFees.destinationCurrency})
            </span>
            <br />
            <br />
            <span style={{ paddingTop: 10 }}>
              The published European Central Bank (ECB) conversion rate for this
              currency pair on your selected transaction date is:
            </span>
            <br />
            <span style={{ fontWeight: 'bold' }}>
              1 {transactionFees.destinationCurrency} ={' '}
              {transactionFees.ecbRate} {transactionFees.sourceCurrency}
            </span>
            <br />
            <br />
            <span style={{ paddingTop: 10 }}>
              Fee for this transaction:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {NumberHelper.currency(
                  transactionFees.bankFee,
                  transactionFees.destinationCurrency
                )}
              </span>
            </span>
            <br />
            <span>
              Mastercard Fee for this transaction:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {NumberHelper.currency(
                  transactionFees.schemeFee,
                  transactionFees.destinationCurrency
                )}
              </span>
            </span>
            <br />
            <br />
            <span>
              Total Fees:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {NumberHelper.currency(
                  transactionFees.totalFee,
                  transactionFees.destinationCurrency
                )}
              </span>
            </span>
            <br />
            <br />
            <span style={{ paddingTop: 10 }}>
              These fees represent a{' '}
              <span style={{ fontWeight: 'bold' }}>
                {transactionFees.totalFeePct}%
              </span>{' '}
              increase on the ECB conversion value for this transaction.
            </span>
            <br />
            <br />
            <span style={{ paddingTop: 10 }}>
              Exchange rates can fluctuate and they may change between the time
              a transaction is made and the time it is deducted from your
              available balance. You agree that any change to the exchange rate
              may be applied immediately and without notice to you.
            </span>
          </div>
        </div>
      </Wrapper>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-6 text-left">
          <SectionTitle>Transaction details</SectionTitle>
          {this._getForm()}
        </div>
        {this._getResult()}
      </div>
    );
  }
}
