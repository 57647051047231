import React from 'react';
import { Link } from 'react-router-dom';
import { AppList } from '../../../index';
import TransactionModel from '../../statements/models/TransactionModel';
import TransactionItem from '../../statements/components/TransactionItem';

export default class LatestTransactions extends React.Component {
  _renderTransactionItem(item: any) {
    return <TransactionItem key={item.id} transaction={item} type="all" />;
  }

  render() {
    return (
      <div style={{ position: 'relative', minHeight: 445, maxHeight: 450 }}>
        <ul
          style={{ padding: 0, margin: 0, maxHeight: 390, overflow: 'hidden' }}
        >
          <AppList
            model={TransactionModel}
            params={{
              limit: 5
            }}
            renderWrapper={(items: React.ReactNode) => (
              <ul style={{ padding: 0 }}>{items}</ul>
            )}
            renderItem={(item: any) => this._renderTransactionItem(item)}
            renderNoItems={() => (
              <div className="col-sm-12 well text-center">
                <div style={{ margin: '20px', color: 'black' }}>
                  <em>
                    Once you start using your card, you'll see transactions
                    here.
                  </em>
                </div>
              </div>
            )}
          />
        </ul>

        <Link to="/statements" className="go-to-inbox">
          View all
        </Link>
      </div>
    );
  }
}
