import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import $ from 'jquery';
import ProfilePage from '../../modules/profile/ProfilePage';
import Dashboard from '../../modules/dashboard/Dashboard';
import CardPage from '../../modules/card/screens/CardPage';
import StatementsPage from '../../modules/statements/StatementsPage';
import NotificationsPage from '../../modules/notifications/NotificationsPage';
import SendMoney from '../../modules/send-money/SendMoney';
import AppTopBar from '../../components/layout/components/AppTopBar';
import AppSideBar from '../../components/layout/components/AppSideBar';
import AppFooter from '../../components/layout/components/AppFooter';
import FxCalculator from '../../modules/fx-calculator/pages/FxCalculator';
import { AppRoute, BottomWaveForMainPage, LPOverridable } from '../../index';

class DefaultLayout extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      collapseSideBar: false
    };
  }

  componentDidUpdate(prevProps: { location: any }) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    this.setState({
      collapseSideBar: false
    });
  }

  btnHandler = () => {
    this.setState(state => {
      return {
        ...state,
        collapseSideBar: !state.collapseSideBar
      };
    });
  };

  _getInnerElements() {
    const currentUrl = this.props.match.url;
    return (
      <Switch>
        {/* <AppRoute path={`${currentUrl}onepaycards`} load={() => import('../modules/onepaycards/PDLCards')} /> */}
        <AppRoute
          path={`${currentUrl}notifications`}
          component={NotificationsPage}
        />
        <AppRoute path={`${currentUrl}send-money`} component={SendMoney} />
        <AppRoute path={`${currentUrl}statements`} component={StatementsPage} />
        <AppRoute path={`${currentUrl}card`} component={CardPage} />
        <AppRoute path={`${currentUrl}profile`}>
          <LPOverridable name="route.profile">
            <Route path={`${currentUrl}profile`} component={ProfilePage} />
          </LPOverridable>
        </AppRoute>
        <AppRoute exact path={`${currentUrl}`} component={Dashboard} />
        <AppRoute
          path={`${currentUrl}fx-calculator`}
          component={FxCalculator}
          // authenticatedOnly
        />
      </Switch>
    );
  }

  /**
   * Render default layout
   *
   * @returns {XML}
   */
  render() {
    if (!this.props.profile) return null;

    const innerElements = this._getInnerElements();

    if (this.state.collapseSideBar) {
      $('body').addClass('noscroll');
      $('html').addClass('noscroll');
    } else {
      $('body').removeClass('noscroll');
      $('html').removeClass('noscroll');
    }

    return (
      <div style={{ position: 'relative' }}>
        <div className="phone-menu-holder" onClick={() => this.btnHandler()}>
          <span className="phone-menu-icon" />
          <span className="phone-menu-icon" />
          <span className="phone-menu-icon" />
        </div>
        <AppTopBar />
        <LPOverridable
          name="dashboard.appSideBar"
          isOpen={this.state.collapseSideBar}
        >
          <AppSideBar isOpen={this.state.collapseSideBar} />
        </LPOverridable>
        <div className="inner">
          <div className="dashboard-main">
            {innerElements}
            <LPOverridable name="dashboard.bottom">
              <BottomWaveForMainPage />
            </LPOverridable>
          </div>

          <div className="clear" />
        </div>
        <AppFooter />
      </div>
    );
  }
}
const mapStateToProps = (state: { auth: { profile: any } }) => {
  return {
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(DefaultLayout);
