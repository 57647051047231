/* eslint-disable default-case */
import React from 'react';
import ModalService from '../../../logic/services/ModalService';
import Logger from '../../../util/Logger';
import { CustomIcon, ModalDialog, AppButton, AppIcon } from '../../../index';
import { CircularProgress } from '@material-ui/core';

export const NAME = 'LOADING_MODAL';


export default class LoadingModal extends React.Component {

  state = {
  }

  constructor(props) {
    super(props);
    this.defaults = {
      changeContent: false,
      loadingSpiner: 0,
      actionState: 'loading',
      visible: false,
      loadingMessage: 'Loading...',
      successMessage: 'Success',
      failureMessage: 'Action Failed',
      loadingIcon: 'md.refresh',
      successIcon: 'md.done',
      failureIcon: 'md.close',
      closeTimeout: 1000,
      beforeCloseTimeout: 1000,
      showSuccess: true,
      manualClose: false,
      beforeClose: () => { },
      afterClose: () => { }
    }
    this.state = {
      ...this.defaults
    }
  }

  success(message, options = {}) {
    this.setState({
      ...this.defaults,
      ...options,
      successMessage: message,
      actionState: 'success',
      manualClose: true,
      visible: true
    });
  }

  failure(message, options = {}) {
    this.setState({
      ...this.defaults,
      ...options,
      failureMessage: message,
      actionState: 'failure',
      manualClose: true,
      visible: true
    });
  }

  executeAction(callable, options = {}) {

    this.setState({
      ...this.defaults,
      ...options
    });

    this.setState({
      actionState: 'loading',
      visible: true
    });

    callable()
      .then((result) => {
        if (this.call('showSuccess')) {
          this.setState({
            actionState: 'success',
            actionResult: result
          })
        } else {
          this.setState({
            beforeCloseTimeout: 0,
            closeTimeout: 0
          })
        }
      })
      .catch((e) => {
        Logger.log('Loading error', e)
        this.setState({
          actionState: 'failure',
          actionError: e
        })
      })
      .finally((e) => {
        setTimeout(() => {
          this.state.beforeClose(this.state.actionState);

          setTimeout(() => {
            this.setState({
              visible: this.state.manualClose ? this.state.visible : false
            }, () => {
              this.state.afterClose();
            })
          }, this.state.beforeCloseTimeout)
        }, this.state.closeTimeout)
      });

  }

  call(prop, params = {}) {
    if (!this.state[prop]) {
      return null;
    }

    if (typeof this.state[prop] === 'function') {
      return this.state[prop](...params);
    }

    return this.state[prop];
  }

  _onClose() {
    ModalService.hideModal(NAME);
    this.setState({
      visible: false
    })
  }

  render() {

    // let loaderContent;
    let modalText = null,
      modalIcon = null;

    switch (this.state.actionState) {
      case 'loading':
        modalText = this.state.loadingMessage;
        modalIcon = <CircularProgress size={100} style={{ marginBottom: 20 }} className='circle-progress' />
        break;
      case 'success':
        modalText = this.state.successMessage;
        // modalIcon = <CustomIcon name="thumbs-up" style={{ fontSize: 150, color: '#308CF6', marginBottom: 20 }} />
        modalIcon = <AppIcon name="fa.thumbs-up" style={{ fontSize: 150, marginBottom: 20 }} />
        // modalIcon = <img src={require('WebsiteApp/assets/img/verified.png')} alt="logo" style={{ maxWidth: 250, marginBottom: 15 }} />;
        break;
      case 'failure':
        modalText = this.call('failureMessage', [this.state.actionError]);
        // modalIcon = <CustomIcon name="face-negative" style={{ fontSize: 150, color: '#308CF6', marginBottom: 20 }} />
        modalIcon = <AppIcon name="fa.frown-o" style={{ fontSize: 150, marginBottom: 20 }} />
        // modalIcon = <img src={require('WebsiteApp/assets/img/not-verified.png')} alt="logo" style={{ maxWidth: 250, marginBottom: 15 }} />;
        break;
    }

    if (this.state.renderIcon) {
      let icon = this.state.renderIcon(this.state.actionState);
      if (icon) modalIcon = icon;
    }

    modalIcon = <div style={{minHeight: 160, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{modalIcon}</div>
    return (
      <div>
        <ModalDialog
          show={this.state.visible}
          onClose={() => this._onClose()}
          style={{
            zIndex: 999999999999
          }}
          {...this.props}
        >
          <div className="lock-card-modal">
            <div className='close-btn'>
            {this.state.actionState !== 'loading' && this.state.manualClose ? (<AppIcon name={'fa.times'} style={{ fontSize: 24, color: 'rgba(0,0,0,0.4)', cursor: 'pointer' }} onClick={() => this._onClose()} />) : null }
            </div>
            {modalIcon}
            <h3 style={{ marginTop: 20, marginBottom: 20, textAlign: 'center', fontSize: '1.1rem' }}>{modalText}</h3>
            {/* {this.state.actionState !== 'loading' && this.state.manualClose ? (<AppButton onClick={() => this._onClose()}>Close</AppButton>) : null} */}
          </div>
        </ModalDialog>
      </div>
    )
  }
}


LoadingModal.defaultProps = {
  onInit: () => { }
}

