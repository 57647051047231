import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import LoginModel from '../models/LoginModel';
import { loginTriggerAction } from '../AuthActions';
import {
  AppLayoutWithCard,
  FormSubmitWrapper,
  FormHandler,
  InputField,
  AppCheckboxInput
} from '../../../index';

class LoginPage extends Component<any, any> {
  formHandler: FormHandler;

  constructor(props: Readonly<{}>) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new LoginModel({
        email: ' ',
        password: ' '
      }),
      {
        redirect: false
      }
    );
    this.formHandler.on('submitSuccess', (data: { success: any }) => {
      if (data.success) {
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 3000);
      }
    });
  }

  componentDidMount() {
    this.setState(state => ({
      ...state,
      record: {
        ...state.record,
        email: '',
        password: '',
        remember: false
      }
    }));
  }

  onSubmitSuccess(data: { success: any; data: { token: any; account: any } }) {
    if (data.success && data.data.token) {
      this.props.userLoggedIn(data.data.token, data.data.account);
    }
  }
  _getModalMessage(data: { success: any; message: any }) {
    if (!data.success) {
      return data.message;
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    let formHandler = this.formHandler;
    return (
      <AppLayoutWithCard>
        <h4>Sign in</h4>
        <div className="form-wrapper">
          <FormSubmitWrapper
            formHandler={formHandler}
            buttonClass="gold-button"
            to="/"
            buttonText="SIGN IN"
            getModalMessage={(data: { success: any; message: any }) =>
              this._getModalMessage(data)
            }
          >
            <div className="login-input-wrapper">
              <InputField
                className="input-group"
                materialProps={{
                  fullWidth: true
                }}
                autoComplete={this.state.record.email}
                label="E-mail"
                name="email"
                type="email"
                // placeholder={'E-mail'}
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.record.email}
                handler={formHandler}
              />
            </div>
            <div className="login-input-wrapper">
              <InputField
                className="input-group"
                materialProps={{
                  fullWidth: true
                }}
                autoComplete={this.state.record.password}
                label="Password"
                type="password"
                name="password"
                // placeholder={'Password'}
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.record.password}
                handler={formHandler}
              />
            </div>
          </FormSubmitWrapper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 20,
              marginBottom: 50
            }}
          >
            <Link
              to="/forgot-password"
              style={{
                color: '#6C6C6C',
                textDecoration: 'none',
                fontWeight: 'lighter',
                fontSize: 15
              }}
            >
              {' '}
              Forgot password?{' '}
            </Link>
          </div>

          <AppCheckboxInput
            name="remember"
            id="rememberField"
            htmlFor="rememberField"
            labelText="Remember me next time"
            value={this.state.remember}
            handler={this.formHandler}
          />
        </div>
      </AppLayoutWithCard>
    );
  }
}

const mapDispatchToProps = (
  dispatch: (arg0: { type: string; payload: { token: any } }) => void
) => {
  return {
    userLoggedIn: (token: any, account: any) => {
      return dispatch(loginTriggerAction(token, account));
    }
  };
};

export default connect(null, mapDispatchToProps)(LoginPage);
